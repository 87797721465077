import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import Axios from 'axios';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
//import draftToMarkdown from 'draftjs-to-markdown';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';

class Document extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            documentTitle: "",
            richContent: undefined,
            action: "", // can be "update" or "duplicate"
            editingExisting: false,
            editingNotAvailable: false,
        }
    }

    componentDidMount(){
        this.setState({ redirectTo: null });
        this.loadData();
    }

    loadData = async () => {
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){
            Axios.get("/api/documents/get/"+id).then(response => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

                const markdownString = response.data.result[0].content;
                const rawData = markdownToDraft(markdownString);
                const contentState = convertFromRaw(rawData);
                const newEditorState = EditorState.createWithContent(contentState);

                this.setState({ documentTitle: response.data.result[0].title });
                this.setState({ richContent: newEditorState });

                this.setState({ editingExisting: true });
                this.setState({ editingNotAvailable: true });
            });

        }
    }

    onSaveDocument = async () => {
        if(this.state.documentTitle == ""){
            alert("Je potřeba vyplnit název dokumentu.");
            return
        }

        const contentVariable = this.state.richContent.getCurrentContent();
        const rawObject = convertToRaw(contentVariable);
        const markdownString = draftToMarkdown(rawObject);
        var content = markdownString;

        if(content == ""){
            alert("Je potřeba vyplnit obsah dokumentu.");
            return
        }


        var url = "/api/documents/ins";
        const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
        if(id != undefined && Number.isInteger(Number(id))){ url = "/api/documents/upd/"+id; }

        //alert("Title: "+this.state.documentTitle+"\nContent: "+content);
        //return;

        await Axios.post(url, {
            title: this.state.documentTitle,
            content: content,
        }).then((response) => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

            if(this.state.editingExisting){
                this.setState({ editingNotAvailable: true });
            }
            else {
                navigate("/Document?id="+response.data.result.insertId);
                window.location.reload(true);
            }

            /*const { update } = Object.fromEntries(new URLSearchParams(window.location.search));
            if(update != undefined && Number.isInteger(Number(update))){
                this.setState({ redirectTo: "/Document?id="+update });
            }
            else {
                this.setState({ redirectTo: "/Documents" });
            }*/
        });
    }

    onEditorStateChange = (editorState) => {
        this.setState({ richContent: editorState });
    };

    deleteDocument = () => {
        if (window.confirm("Opravdu chcete smazat tento dokument?") == true) {
            var firstNum = Math.floor(Math.random() * (20 - 1) + 1);
            var secondNum = Math.floor(Math.random() * (20 - 1) + 1);
            var result = firstNum+secondNum;

            const enteredResult = prompt('!TATO AKCE NELZE VZÍT ZPĚT!\nOpravdu víte jistě, že chcete smazat tento dokument?\nPro potvrzení napište výsledek následujícího příkladu.\nPříklad: '+firstNum+" + "+secondNum);
            if(result+"" === enteredResult){

                const { id } = Object.fromEntries(new URLSearchParams(window.location.search));
                if(id != undefined && Number.isInteger(Number(id))){
                    Axios.get("/api/documents/del/"+id).then(response => {
                        if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                        this.setState({ redirectTo: "/Documents" });
                    });
                }

            }
            else {
                alert("Výsledek byl zadán špatně. Dokument nebyl smazán.");
            }

        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }

        return(
            <div className="verticalStack flex" style={{height: "calc(var(--doc-height) - 80px)", overflowY: "scroll"}}>

                <div className="verticalStack">

                    <div className="universalTile">

                        {!this.state.editingExisting ? <span className="fontPoppinsSemiBold15">{"Nový dokument"}</span> : null }
                        {!this.state.editingExisting ? <div style={{height: 10}}></div> : null }

                        <div className="verticalStack" style={{padding: 12, backgroundColor: "#F4F4F8"}}>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Název dokumentu:*</span>
                                <input className="profileTileInputText" type="text" style={{flex: 1, fontSize: 20}} id="documentTitle" name="documentTitle" value={this.state.documentTitle} onChange={(event) => this.setState({documentTitle: event.target.value})}/>
                            </div>

                            <div style={{height: 10}}></div>

                            <div className="verticalStack">
                                <span className="fontPoppinsRegular13" style={{marginBottom: 10}}>Obsah:*</span>

                                <Editor
                                    toolbarHidden={this.state.editingNotAvailable}
                                    readOnly={this.state.editingNotAvailable}
                                    editorState={this.state.richContent}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    wrapperStyle={{ minHeight: 400, border: "0px solid black", backgroundColor: "white"}}
                                    editorStyle={{ paddingLeft: 10, paddingRight: 10, height: this.state.editingNotAvailable ? "400px" : "347px"}}
                                    onEditorStateChange={this.onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'link', 'remove', 'history'],
                                        blockType: {
                                          options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6", "Blockquote"],
                                        },
                                        list: {
                                          options: ["unordered", "ordered"],
                                        },
                                    }}
                                />
                            </div>

                            <div style={{height: 15}}></div>

                            <div className="horizontalStack">
                                {!this.state.editingNotAvailable ? <RoundButton title={"Uložit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={this.onSaveDocument} whiteText={true} /> : null }
                                {this.state.editingExisting && this.state.editingNotAvailable ? <RoundButton title={"Upravit"} style={{color: "white", backgroundColor: "#ff6600"}} onClick={() => this.setState({ editingNotAvailable: false })} whiteText={true} /> : null }
                                {this.state.editingExisting && !this.state.editingNotAvailable ? <RoundButton title={"Smazat"} style={{color: "white", backgroundColor: "gray", marginLeft: 10}} onClick={this.deleteDocument} whiteText={true} /> : null }
                            </div>

                        </div>

                    </div>

                    <div style={{height: 20}}></div>
                </div>
            </div>
        );
    }
}

export default Document;