import React, { useState, useEffect } from "react";
import '../styles/tiles.css';
import '../styles/other.css';
import Axios from 'axios';

// import utilities
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import RoundButton from '../components/RoundButton.js';
import SearchBox from '../components/SearchBox.js';
import GrayTextButton from '../components/GrayTextButton.js';

class SearchTileSongs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            windowHeight: 1000,
        }
    }

    async componentDidMount(){
        window.addEventListener('resize', this.checkWindowWidth);

        this.checkWindowWidth();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkWindowWidth();
    }

    componentWillUnmount() { window.removeEventListener('resize', this.checkWindowWidth); }
    checkWindowWidth = () => { this.setState({ windowHeight: window.innerWidth }); }

    loadData = () => {
    }

    render() {
        return (
            <div className="searchTile">

                <div className="verticalStack">
                    <div className="horizontalStackCenter">
                        <div className="" style={{minWidth: "200px", maxWidth: "380px", width: "100%"}}>
                            <SearchBox searchText={this.props.searchText} setSearchText={this.props.setSearchText} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default SearchTileSongs;
