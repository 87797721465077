import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import { navigate } from "@reach/router";
import CsvDownloadButton from 'react-json-to-csv';

// import components
import RoundButton from '../components/RoundButton.js';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities } from '../utilities/PagingUtilities.js';
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import PublicSongsTile from '../tiles/PublicSongsTile.js';
import SearchTileSongs from '../tiles/SearchTileSongs.js';

// import assets
import logo2 from '../assets/logo2.png';

class PublicSongs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectTo: null,
            songsArray: ([]),
            recordsOnOnePage: localStorage.getItem("recordsOnOnePage") || 25, // number of songs showing on one page, 25 would be default, 5 is for testing
            totalNumberOfContacts: 100,
            actualPage: 0, // starts with pageNumber=0, then 1, 2, 3,...
            windowHeight: 1000,
            useAdvancedSearch: false,
            searchAdvancedSongs: ({searchingOption: "and", title: "", author: "", songKey: "", capo: "", tags: "", tempo: ""}),
            sortBy: localStorage.getItem("sortByPublicSongs") || "", // name
            sortTp: localStorage.getItem("sortTpPublicSongs") || "asc", // asc, desc
        }
    }

    async componentDidMount(){
        this.setState({ redirectTo: null });
        await this.setState({ useAdvancedSearch: false });
        this.loadData();
        window.addEventListener('resize', this.checkWindowWidth);

        this.checkWindowWidth();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkWindowWidth();
    }

    componentWillUnmount() { window.removeEventListener('resize', this.checkWindowWidth); }
    checkWindowWidth = () => { this.setState({ windowHeight: window.innerWidth }); }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }
    }

    loadData = () => {
        if(!this.state.useAdvancedSearch){
            /*var url = "/api/publicSongs/get?limit="+this.state.recordsOnOnePage+"&offset="+(this.state.actualPage*this.state.recordsOnOnePage);
            if(this.props.searchText != undefined && this.props.searchText != ""){
                url = "/api/publicSongs/get?search="+this.props.searchText+"&limit="+this.state.recordsOnOnePage+"&offset="+(this.state.actualPage*this.state.recordsOnOnePage);
            }*/

            /*Axios.get("/api/publicSongs/get").then(response => {
                //if(checkForErrorsInRequest(response.data.msg) == 1){ return; }

                this.setState({ songsArray: response.data.result });
                this.setState({ totalNumberOfContacts: response.data.count });
            });*/

            /* here I send the parameters in "body" because I need to have short URL to recognize it on backend and do not want TOKEN ACCESS on this API */
            var url = "/api/publicSongs/get";
            if(this.state.sortBy != ""){
                Axios.post(url, {
                    search: this.props.searchText ? this.props.searchText : "",
                    sort_by: this.state.sortBy,
                    sort_tp: this.state.sortTp,
                    limit: ""+this.state.recordsOnOnePage+"",
                    offset: ""+(this.state.actualPage*this.state.recordsOnOnePage)+"",
                }).then((response) => {
                    if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                    this.setState({ songsArray: response.data.result });
                    this.setState({ totalNumberOfContacts: response.data.count });
                });
            }
            else {
                Axios.post(url, {
                    search: this.props.searchText ? this.props.searchText : "",
                    limit: ""+this.state.recordsOnOnePage+"",
                    offset: ""+(this.state.actualPage*this.state.recordsOnOnePage)+"",
                }).then((response) => {
                    if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                    this.setState({ songsArray: response.data.result });
                    this.setState({ totalNumberOfContacts: response.data.count });
                });
            }
        }
        else {
            Axios.post("/api/publicSongs/get_adv", {
                limit: this.state.recordsOnOnePage,
                offset: (this.state.actualPage*this.state.recordsOnOnePage),
                searchOption: this.state.searchAdvancedSongs.searchingOption,
                title: this.state.searchAdvancedSongs.title,
                author: this.state.searchAdvancedSongs.author,
                songKey: this.state.searchAdvancedSongs.songKey,
                capo: this.state.searchAdvancedSongs.capo,
                tags: this.state.searchAdvancedSongs.tags,
                tempo: this.state.searchAdvancedSongs.tempo,
            }).then((response) => {
                if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
                this.setState({ songsArray: response.data.result });
                this.setState({ totalNumberOfContacts: response.data.count });
                //alert("Nalezeno "+response.data.count+" výsledků.");
            });
        }

    }

    openLogin = () => {
        window.location.href = "/Login";
    }

    setSearchAdvancedSongs = async (objectAdvancedSearch) => {
        await this.setState({ searchAdvancedSongs: objectAdvancedSearch });
        await this.setState({ useAdvancedSearch: true });
        this.loadData();
    }

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    changeSorting = async (attribute) => {
        this.goToFirstPage();
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            localStorage.setItem("sortTpPublicSongs", "desc");
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            localStorage.setItem("sortByPublicSongs", "");
            localStorage.setItem("sortTpPublicSongs", "desc");
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            localStorage.setItem("sortByPublicSongs", attribute);
            localStorage.setItem("sortTpPublicSongs", "asc");
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />
        }
        return (
            <div className="verticalStack flex" style={{height: "var(--doc-height)", backgroundColor: "#f4f4f8", overflowY: "scroll"}}>

                <div className="mainTopMenu horizontalStackCenter">

                    <img style={{height: "50px"}} src={logo2} className="clickable" onClick={() => {navigate("/");window.location.reload();}}/>

                    <div style={{width: 10}}></div>

                    <span className="logoText clickable" onClick={() => {navigate("/");window.location.reload();}}>{"Sizej Web"}</span>

                    <div className="flex"/>

                    <RoundButton title={"Přihlásit se"} style={{marginRight: 20}} onClick={() => this.openLogin()} />
                </div>

                <SearchTileSongs searchText={this.props.searchText} setSearchText={this.props.setSearchText} searchAdvancedSongs={this.state.searchAdvancedSongs} setSearchAdvancedSongs={this.setSearchAdvancedSongs} />
                <div className="horizontalStackCenter tableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    {this.state.windowHeight >= 900 ? <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px"}}>{"Seznam veřejných chval"}</span> : null }
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "12px"}}>{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    {this.state.windowHeight >= 900 ? <CsvDownloadButton data={this.state.songsArray} filename={"export_songs.csv"} className="fontPoppinsRegular13Gray clickable onHoverToOrange" style={{backgroundColor: "transparent", border: "none", paddingRight: "30px"}}>Export CSV</CsvDownloadButton> : null}
                    <span className="fontPoppinsRegular13Gray">{"Počet chval:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                <PublicSongsTile songsArray={this.state.songsArray} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} />

            </div>
        );
    }
}

export default PublicSongs;
