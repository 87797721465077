import React, { useState, useEffect } from "react";
import '../styles/other.css';
import '../styles/pages.css';
import Axios from 'axios';
import CsvDownloadButton from 'react-json-to-csv';

// import utilities
import { moreRecordsOnPageUtilities, lessRecordsOnPageUtilities, goToPrevPageUtilities, goToNextPageUtilities, goToFirstPageUtilities, goToLastPageUtilities } from '../utilities/PagingUtilities.js';
import { checkForErrorsInRequest } from '../utilities/RequestsUtilities.js';

// import components
import DocumentsTile from '../tiles/DocumentsTile.js';
import SearchTileDocuments from '../tiles/SearchTileDocuments.js';

class Documents extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            documentsArray: ([]),
            /*documentsArray: ([{{id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"},
                            {id_document: 2, title: "Nazev dokumentu, content: "Obsah dokumentu"}]),*/
            recordsOnOnePage: localStorage.getItem("recordsOnOnePage") || 25, // number of documents showing on one page, 25 would be default, 5 is for testing
            totalNumberOfContacts: 100,
            actualPage: 0, // starts with pageNumber=0, then 1, 2, 3,...
            windowHeight: 1000,
            sortBy: localStorage.getItem("sortByDocuments") || "", // name
            sortTp: localStorage.getItem("sortTpDocuments") || "asc", // asc, desc
        }

    }

    async componentDidMount(){
        this.loadData();

        this.setState({ redirectTo: null });
        window.addEventListener('resize', this.checkWindowWidth);

        this.checkWindowWidth();
        await new Promise(resolve => setTimeout(resolve, 50));
        this.checkWindowWidth();
    }

    componentWillUnmount() { window.removeEventListener('resize', this.checkWindowWidth); }
    checkWindowWidth = () => { this.setState({ windowHeight: window.innerWidth }); }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.searchText !== this.props.searchText) {
            this.setState({ actualPage: 0 });
            this.loadData();
        }
    }

    loadData = () => {
        /*Axios.get('/api/cont/get?tp=1&limit='+this.state.recordsOnOnePage+'&offset='+(this.state.actualPage*this.state.recordsOnOnePage)+'&search='+this.props.searchText).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.setState({ documentsArray: response.data.result })
        });*/

        var url = "/api/documents/get?limit="+this.state.recordsOnOnePage+"&offset="+(this.state.actualPage*this.state.recordsOnOnePage);
        if(this.props.searchText != undefined && this.props.searchText != ""){
            url = "/api/documents/get?search="+this.props.searchText+"&limit="+this.state.recordsOnOnePage+"&offset="+(this.state.actualPage*this.state.recordsOnOnePage);
        }
        if(this.state.sortBy != ""){
            url = url + "&sort_by="+this.state.sortBy+"&sort_tp="+this.state.sortTp.toUpperCase();
        }

        Axios.get(url).then(response => {
            if(checkForErrorsInRequest(response.data.msg) == 1){ return; }
            this.setState({ documentsArray: response.data.result });
            this.setState({ totalNumberOfContacts: response.data.count });
        });

    }

    goToFirstPage = async () => {
        await this.setState({ actualPage: goToFirstPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToPrevPage = async () => {
        await this.setState({ actualPage: goToPrevPageUtilities(this.state.actualPage) });
        this.loadData();
    }

    goToNextPage = async () => {
        await this.setState({ actualPage: goToNextPageUtilities(this.state.actualPage, this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    goToLastPage = async () => {
        await this.setState({ actualPage: goToLastPageUtilities(this.state.recordsOnOnePage, this.state.totalNumberOfContacts) });
        this.loadData();
    }

    moreRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: moreRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    lessRecordsOnPage = async () => {
        await this.setState({ recordsOnOnePage: lessRecordsOnPageUtilities(this.state.recordsOnOnePage) });
        this.loadData();
    }

    changeSorting = async (attribute) => {
        this.goToFirstPage();
        if(this.state.sortBy == attribute && this.state.sortTp == "asc") {
            localStorage.setItem("sortTpDocuments", "desc");
            await this.setState({ sortTp: "desc" });
        }
        else if(this.state.sortBy == attribute && this.state.sortTp == "desc") {
            localStorage.setItem("sortByDocuments", "");
            localStorage.setItem("sortTpDocuments", "desc");
            await this.setState({ sortBy: "" });
            await this.setState({ sortTp: "desc" });
        }
        else {
            localStorage.setItem("sortByDocuments", attribute);
            localStorage.setItem("sortTpDocuments", "asc");
            await this.setState({ sortBy: attribute });
            await this.setState({ sortTp: "asc" });
        }
        this.loadData();
    }

    render() {
        return (
            <div className="verticalStack flex" style={{height: this.state.windowHeight >= 900 ? "calc(var(--doc-height) - 80px)" : "calc(var(--doc-height) - 80px)"}}>
                <SearchTileDocuments searchText={this.props.searchText} setSearchText={this.props.setSearchText} />
                <div className="horizontalStackCenter tableListingBar">
                    <span onClick={this.goToFirstPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{"<<"}</span>
                    <span onClick={this.goToPrevPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px"}}>{"<"}</span>
                    {this.state.windowHeight >= 900 ? <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "20px"}}>{"Seznam dokumentů"}</span> : null }
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "12px"}}>{(this.state.actualPage*this.state.recordsOnOnePage)+1}{"-"}{(this.state.actualPage+1)*this.state.recordsOnOnePage > this.state.totalNumberOfContacts ? this.state.totalNumberOfContacts : (this.state.actualPage+1)*this.state.recordsOnOnePage }</span>
                    <span className="fontPoppinsRegular13Gray" style={{paddingLeft: "6px", paddingRight: "6px"}}>{"of"}</span>
                    <span className="fontPoppinsSemiBold16">{this.state.totalNumberOfContacts}</span>
                    <span onClick={this.goToNextPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange" style={{paddingLeft: "20px", paddingRight: "20px"}}>{">"}</span>
                    <span onClick={this.goToLastPage} className="fontPoppinsSemiBold16 clickable onHoverToOrange">{">>"}</span>
                    <div className="flex"/>
                    {this.state.windowHeight >= 900 ? <CsvDownloadButton data={this.state.documentsArray} filename={"export_documents.csv"} className="fontPoppinsRegular13Gray clickable onHoverToOrange" style={{backgroundColor: "transparent", border: "none", paddingRight: "30px"}}>Export CSV</CsvDownloadButton> : null}
                    <span className="fontPoppinsRegular13Gray">{"Počet dokumentů:"}</span>
                    <span className="fontPoppinsSemiBold16" style={{paddingLeft: "10px", paddingRight: "10px"}}>{this.state.recordsOnOnePage}</span>
                    <div className="verticalStackCenter">
                        <div onClick={this.moreRecordsOnPage} className="clickable buttonPagingUp"></div>
                        <div style={{height: "3px"}}></div>
                        <div onClick={this.lessRecordsOnPage} className="clickable buttonPagingDown"></div>
                    </div>
                </div>
                <DocumentsTile documentsArray={this.state.documentsArray} changeSorting={this.changeSorting} sortBy={this.state.sortBy} sortTp={this.state.sortTp} />
            </div>
        );
    }
}

export default Documents;
