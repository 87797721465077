import React, { useState, useEffect } from "react";
import { Link, Redirect, useNavigate } from "react-router-dom";
import '../App.css';
import '../styles/pages.css';
import '../styles/other.css';
import Axios from 'axios';
import CookieConsent from "react-cookie-consent";
import { useGoogleLogin } from "@react-oauth/google";

// import assets
import logo2 from '../assets/logo2.png';

async function loginUser(credentials) {
    console.log(credentials);
    return fetch(Axios.defaults.baseURL+'api/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
   .then(data => data.json())

    //Using Axios is not working
    //await Axios.get('/api/login2',{credentials}).then(response => alert(response));

    /*return Axios.post('/api/login2', credentials, { withCredentials: true, credentials: 'include' })
        .then(response => alert(response));*/
}

function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [logged, setLogged] = useState(false);
    //const history = useNavigate();

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            console.log(credentialResponse);

            const token = credentialResponse.access_token;

            // SEND THE ACCESS TOKEN TO THE "LOGIN API" ON BACKEND
            const response = await loginUser({
              token
            });

            console.log(response);
            if(response.loginWasSuccessful == 1){
                console.log("SUCCESSFUL LOGIN");
                setToken(response.token);
            }
            else {
                console.log("UNSUCCESSFUL LOGIN");
                alert("Wrong username or password.");
            }
            window.location.href = "/";

            // VERIFICATION OF ACCESS_TOKEN
            /*try {
                const res = fetch("https://www.googleapis.com/oauth2/v3/userinfo?access_token="+credentialResponse.access_token)
                .then((data) => data.json())
                .then((data) => console.log(data));
                //alert("Result: "+res.name);
            }
            catch (err){ alert("Error: "+err) }*/

            //var credentialResponseDecoded = jwtDecode(tokenResponse.access_token);
        },
        onError: () => {
            alert("Google Login Failed");
        }
    });

    const handleSubmit = async e => {
        e.preventDefault();
        //console.log(username);
        //console.log(password);
        const response = await loginUser({
          username,
          password
        });

        console.log(response);
        if(response.loginWasSuccessful == 1){
            console.log("SUCCESSFUL LOGIN");
            setToken(response.token);
        }
        else {
            console.log("UNSUCCESSFUL LOGIN");
            alert("Wrong username or password.");
        }
        window.location.href = "/";
    }

    useEffect(() => {
        localStorage.setItem("actualPage", "sign");
    }, []);

    const openPromoPage = () => {
        window.location.href = "/Promo";
    }

    const openPublicSongs = () => {
        window.location.href = "/PublicSongs";
    }

    const openRegistrationPage = () => {
        window.location.href = "/Registration";
    }

    const openForgotPasswordPage = () => {
        window.location.href = "/ForgotPassword";
    }

	return(
        <div className="loginPage">
            <div className="horizontalStackCenter">


                <div className="verticalStackCenter flex" style={{width: "100%", height: "var(--doc-height)", overflowY: "scroll"}}>

                        <div className="verticalStack" style={{alignItems: "center", justifyContent: "center", marginLeft: 20, marginRight: 20, marginTop: 20, minWidth: "250px", maxWidth: "380px"}}>
                            {window.innerWidth >= 900 ? <img style={{height: "120px", width: "120px"}} src={logo2}/> : null }
                            {window.innerWidth < 900 ? <img style={{height: "90px", width: "90px"}} src={logo2}/> : null }

                            {window.innerWidth >= 900 ? <span className="loginTitle" style={{textAlign: "center"}}>{"Sizej Web"}</span> : null }
                            {window.innerWidth < 900 ? <span className="loginTitle" style={{fontSize: 40, textAlign: "center"}}>{"Sizej Web"}</span> : null }

                            {window.innerWidth >= 900 ? <div style={{height: 48}}></div> : null }
                            {window.innerWidth < 900 ? <div style={{height: 20}}></div> : null }

                            <div className="horizontalStackCenter" style={{width: "100%"}}>
                                <span className="fontPoppinsRegular13" style={{width: "100%", color: "white", textAlign: "center"}}>Dostupné bez přihlášení:</span>
                            </div>

                            <div className="horizontalStackCenter" style={{width: "100%", marginTop: "12px"}}>
                                <span className="loginPageLoginButton clickable fontPoppinsRegular13" style={{width: "100%", backgroundColor: "#7483cc", color: "white", padding: 20, textAlign: "center"}} onClick={() => openPublicSongs()}>Veřejný seznam chval</span>
                            </div>

                            <div className="horizontalStackCenter" style={{width: "100%", marginTop: "24px"}}>
                                <span className="loginPageLoginButton clickable fontPoppinsRegular13" style={{width: "100%", textAlign: "center"}} onClick={() => openPromoPage()}>Informace o webu</span>
                            </div>

                            {window.innerWidth >= 900 ? <div style={{height: 48}}></div> : null }
                            {window.innerWidth < 900 ? <div style={{height: 20}}></div> : null }

                            <div className="horizontalStackCenter" style={{width: "100%", textAlign: "center"}}>
                                <span className="fontPoppinsRegular13" style={{width: "100%", color: "white"}}>Přihlášení do týmu:</span>
                            </div>

                            <form onSubmit={handleSubmit} style={{width: "100%"}}>

                                <div className="loginPageEmailElement" style={{marginTop: "12px"}}>
                                    <input className="loginPageEmailInput fontPoppinsRegular13" type="text" placeholder="Username" onChange={e => setUserName(e.target.value)} />
                                </div>

                                <div className="horizontalStackCenter loginPagePasswordElement" style={{marginTop: "24px"}}>
                                    <input className="loginPagePasswordInput fontPoppinsRegular13" type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                    <button className="loginPageLoginButton clickable" type="submit"><span className="fontPoppinsRegular13">{"Přihlásit se"}</span></button>
                                </div>

                            </form>

                            <div className="horizontalStackCenter" style={{width: "100%", marginTop: "24px", marginBottom: "24px"}}>
                                <span className="fontPoppinsRegular13White clickable" style={{paddingLeft: "15px", textAlign: "center"}} onClick={() => openRegistrationPage()}>Vytvořit účet</span>
                                <div style={{maxWidth: 30, minWidth: 10, width: "100%"}}></div>
                                <span className="fontPoppinsRegular13White clickable" style={{textAlign: "center"}} onClick={() => loginWithGoogle()}>Google Login</span>
                                <div style={{maxWidth: 30, minWidth: 10, width: "100%"}}></div>
                                <span className="fontPoppinsRegular13White clickable" style={{paddingRight: "15px", textAlign: "center"}} onClick={() => openForgotPasswordPage()}>Zapomenuté heslo</span>
                            </div>

                            {/*<div className="horizontalStackCenter" style={{width: "100%", marginTop: "24px"}}>
                                <span className="loginPageLoginButton clickable fontPoppinsRegular13" style={{width: "100%"}} onClick={() => openPublicSongs()}>Seznam chval</span>
                                <div style={{width: "50px", height: "50px"}}></div>
                                <span className="loginPageLoginButton clickable fontPoppinsRegular13" style={{width: "100%"}} onClick={() => openPromoPage()}>Informace o webu</span>
                            </div>*/}

                            {/*<div className="horizontalStackCenter" style={{justifyContent: "center", width: "100%", marginTop: "24px"}}>
                                <span className="fontPoppinsRegular13White clickable" style={{paddingLeft: "15px"}}>Další odkaz</span>
                            </div>*/}

                        </div>
                </div>


            </div>

            <CookieConsent
                location="bottom"
                buttonText="Povolit"
                cookieName="cookieConsent"
                style={{ background: "#556EE6" }}
                buttonStyle={{ fontFamily: "PoppinsRegular", color: "black", background: "#d4daf9", fontSize: "13px", borderRadius: 20 }}
                expires={150}>
                    <span className="fontPoppinsRegular13White">Kliknutím na „Povolit“ dáváte souhlas se zpracováním cookies a dalších osobních údajů.</span>{" "}
                    {/*<span style={{ fontSize: "10px" }}>This bit of text is smaller</span>*/}
            </CookieConsent>
        </div>
	);

}
export default Login;